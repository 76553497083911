import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatShopName } from "../utils/formatShopName";
import ProductCard from "./ProductCard";
import ProductInfo from "./ProductInfo";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Product {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id: number;
}

interface InfomationProduct {
  id: any;
  link: string | undefined;
  shipping: string;
  coupon: string;
  dialogs: string;
  price: string;
  title: string;
  imageUrl: string;
}

interface ProductLayoutProps {
  shopName: string;
  headerColor: string;
  backgroundColor: string;
  produtos: Product[];
  informacaoproduto: InfomationProduct;
  logourl: string;
}
interface ShopData {
  nomedaloja: string;
  domainName: string;
  colorHeader: string;
  backgroundColor: string;
  products: Product[];
  linkdogrupo: string;
  logourl: string;
}

const ProductLeyout: React.FC<ProductLayoutProps> = ({
  shopName,
  headerColor,
  backgroundColor,
  produtos,
  informacaoproduto,
  logourl,
}) => {
  const navigate = useNavigate();
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [shopData] = useState<ShopData | null>(null);

  useEffect(() => {
    const initialItemsPerPage = 10; // ou mais, dependendo do layout
    setDisplayedProducts(produtos.slice(0, initialItemsPerPage));
  }, [produtos]);


  const loadMoreProducts = useCallback(() => {
    if (loading) return;

    setLoading(true);

    // Simulate delay for loading more products
    setTimeout(() => {
      const itemsPerPage = 10;
      const nextPage = currentPage + 1;
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Load more products
      const moreProducts = produtos.slice(startIndex, endIndex);
      setDisplayedProducts((prev) => [...prev, ...moreProducts]);
      setCurrentPage(nextPage); // Update current page
      setLoading(false);
    }, 300);
  }, [loading, currentPage, produtos]);

  useEffect(() => {
    const handleScroll = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading) {
        loadMoreProducts();
      }
    };

    const observer = new IntersectionObserver(handleScroll, { threshold: 0.1 });

    // Use a variable to hold the current ref value
    const currentLoaderRef = loaderRef.current;

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loading, loadMoreProducts]);

  const formattedShopName = shopData?.nomedaloja
    ? formatShopName(shopData.nomedaloja)
    : "Acesse minha loja";
  return (
    <>
      <Helmet>
        <title>{formattedShopName}</title>
        <meta name="description" content={`${shopData?.nomedaloja}`} />
        <meta property="og:title" content={formattedShopName} />
        <meta property="og:image" content={shopData?.logourl} />
        <meta property="og:description" content={`${shopData?.nomedaloja}`} />
        <meta property="og:url" content={window.location.href} />
        <link rel="icon" href={shopData?.logourl} />
      </Helmet>

      <div
        style={{
          backgroundColor: '#F0F2F5',
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <header style={{
          backgroundColor: '#1A4BAF',
        }} className="header">
          <div className="header-content">
            <img
              src="https://divulgadorinteligente.com/cdn-cgi/imagedelivery/MZSoNqg03WJMyxIyXeG5YA/848b4999-93d5-4b03-445a-c81ed97f9a00/medium"
              className="shop-header-logo"
              alt="Logo da Loja"
            />
            <h5
              style={{
                color: "white",
                fontSize: "17px",
                display: "flex",
                flexDirection: "row",
                whiteSpace: "nowrap",
              }}
            >
              {formatShopName(shopName)}
            </h5>
            <nav>
              <a href="#home" onClick={() => navigate("/")}>
                Início
              </a>
            </nav>
          </div>
        </header>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyItems: "center",
          }}
        >
          <div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                flexDirection: "column",
              }}
            >
              <section
                style={{
                  maxWidth: 900,
                }}
                className="section"
              >
                {
                  <ProductInfo
                    imageUrl={informacaoproduto.imageUrl || ""}
                    title={informacaoproduto?.title || ""}
                    price={informacaoproduto?.price || ""}
                    dialogs={informacaoproduto?.dialogs || ""}
                    coupon={informacaoproduto?.coupon || ""}
                    shipping={informacaoproduto?.shipping || ""}
                    link={informacaoproduto?.link}
                    id={informacaoproduto.id}
                  />
                }
              </section>
              <div className="contact-select" style={{ marginTop: 20 }}>
                <p>Já está no meu grupo de promoções?</p>
                <span>
                  Receba no Whatsapp as melhores promoções e economize mais.
                </span>
                <a href="https://promosninjas.com.br/whatsapp" style={{ borderRadius: '50px' }}>
                  <img
                    src="https://img.icons8.com/?size=100&id=16712&format=png&color=ffffff"
                    alt="link whatsApp"
                  />
                  Clique aqui e entre
                </a>
              </div>
            </span>
            <main
              style={{
                maxWidth: 900,
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                flexDirection: "column",
              }}
              className="main"
            >
              <p
                style={{
                  color: "black",
                  padding: "20px",
                  textAlign: "center",
                  marginBottom: 0,
                  width: "100%",
                }}
              >
                Ver mais novidades
              </p>
              {displayedProducts && displayedProducts.length > 0 ? (
                <div className="product-grid">
                  {displayedProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      imageUrl={product.imageUrl || ""}
                      title={product?.title || ""}
                      price={product?.price || ""}
                      dialogs={product?.dialogs || ""}
                      coupon={product?.coupon || ""}
                      shipping={product?.shipping || ""}
                      link={product?.link}
                      id={product.id}
                    />
                  ))}
                </div>
              ) : (
                <p>Sem novos produtos disponíveis no momento.</p>
              )}
              <div ref={loaderRef} style={{ height: "20px" }} />
            </main>
          </div>
          <div className="contact" style={{ marginTop: 20 }}>
            <p>Já está no meu grupo de promoções?</p>
            <span>
              Receba no Whatsapp as melhores promoções e economize mais.
            </span>
            <a href="https://promosninjas.com.br/whatsapp" style={{ borderRadius: '50px' }}>
              <img
                src="https://img.icons8.com/?size=100&id=16712&format=png&color=ffffff"
                alt="link whatsApp"
              />
              Clique aqui e entre
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductLeyout;
