import React, { useEffect, useState } from "react";
import api from "../api/api";
import { formatShopName } from "../utils/formatShopName";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ProductLeyout from "./ProductLeyout";
import { useParams } from "react-router-dom";

interface Product {
  imageUrl: string;
  title: string;
  price: string;
  dialogs: string;
  coupon?: string;
  shipping?: string;
  logourl: string;
  id: number;
}

interface InfomationProduct {
  informacaoproduto: Product[];
}

interface ShopData {
  nomedaloja: string;
  domainName: string;
  colorHeader: string;
  backgroundColor: string;
  products: Product[];
  logourl: string;
}

const ProductPage: React.FC = () => {
  const { id: idProduct } = useParams();
  const [shopData, setShopData] = useState<ShopData | any>(null);
  const [produtos, setProdutos] = useState<Product | any>(Array);
  const [infoProduto, setInfoProduto] = useState<InfomationProduct | any>(
    Array
  );
  const shopName = "promosninja";
  useEffect(() => {
    api
      .get(`/lojas/${shopName}`)
      .then((response) => {
        const responses = response.data;
        console.log(responses); // Verifica os dados recebidos no console
        setShopData(responses);
      })
      .catch((error) => console.error("Erro ao buscar dados da loja:", error));
  }, [shopName]);

  useEffect(() => {
    api
      .get(`/produtos/${shopName}`)
      .then((response) => {
        const responses = response.data;
        setProdutos(responses);
      })
      .catch((error) => console.error("Erro ao buscar dados da loja:", error));
  }, []);

  useEffect(() => {
    // Buscar produto pelo Id da Url
    api
      .get(`/produtos/${shopName}/${idProduct}`)
      .then((response) => {
        const responses = response.data;
        console.log(responses);
        setInfoProduto(responses);
      })
      .catch((error) =>
        console.error("Erro ao buscar dados do produto", error)
      );
  }, [shopName, idProduct]);

  useEffect(() => {
    if (shopData) {
      // Atualiza o título da página
      const formattedShopName = formatShopName(shopData.nomedaloja);
      document.title = formattedShopName;
      // Atualiza o favicon
      const favicon = document.getElementById(
        "favicon"
      ) as HTMLLinkElement | null;
      if (favicon) {
        favicon.href = shopData.logourl;
      } else {
        const link = document.createElement("link");
        link.id = "favicon";
        link.rel = "icon";
        link.href = shopData.logourl;
        document.head.appendChild(link);
      }
    }
  }, [shopData]);

  if (!shopData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ProductLeyout
        shopName={shopData.nomedaloja}
        headerColor={shopData.colorHeader}
        backgroundColor={"#bfa9ea"}
        produtos={produtos}
        informacaoproduto={infoProduto}
        logourl={shopData.logourl}
      />
    </>
  );
};

export default ProductPage;
