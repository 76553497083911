import React from "react";
import mercadolivreLogo from "../assets/livre.png";
import shopeeLogo from "../assets/shopee.png";
import magaluLogo from "../assets/magalu.png";
import amazonLogo from "../assets/amzn.png";
import NaturaLogo from "../assets/Natura.png";
import couponLogo from "../assets/cupom.png";
import freeshippingLogo from "../assets/freeshipping.png";

interface ProductCardProps {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id: number;
}

const ProductCard: React.FC<ProductCardProps> = ({
  imageUrl,
  title,
  price,
  dialogs,
  coupon,
  shipping,
  link,
  id,
}) => {
  const isMercadolivre = link?.includes("mercadolivre");
  const isShopee = link?.includes("shopee");
  const isMagalu = link?.includes("magazine");
  const isAmazon = link?.includes("amzn");
  const isNatura = link?.includes("natura");

  let logoSrc;
  if (isMercadolivre) {
    logoSrc = mercadolivreLogo;
  } else if (isShopee) {
    logoSrc = shopeeLogo;
  } else if (isMagalu) {
    logoSrc = magaluLogo;
  } else if (isAmazon) {
    logoSrc = amazonLogo;
  } else if (isNatura) {
    logoSrc = NaturaLogo;
  }

  return (
    <a href={`/produto/${id}`}>
      <div className="product-card">
        <div className="product-image-wrapper">
          <img
            src={imageUrl}
            alt="imagseurl"
            className="product-image"
            style={{ width: "100%", height: 150 }}
          />
          {logoSrc && (
            <div className="logo-container">
              <img src={logoSrc} alt="logosrc" className="platform-logo" />
            </div>
          )}
        </div>
        <div className="product-info">
          <h3>{title}</h3>
        </div>
        <div className="product-footer">
          {coupon && (
            <span className="coupon">
              <img src={couponLogo} alt="cupom" className="coupon-logo" />
              <span>Tem cupom</span>
            </span>
          )}
          {shipping && (
            <span className="shipping">
              <img src={freeshippingLogo} alt="shipping" className="freeshipping-logo" />
              {shipping}
            </span>
          )}
          <p className="price">R${price}</p>
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
