import React, { useState } from "react";
import mercadolivreLogo from "../assets/livre.png";
import shopeeLogo from "../assets/shopee.png";
import magaluLogo from "../assets/magalu.png";
import amazonLogo from "../assets/amzn.png";
import NaturaLogo from "../assets/Natura.png";
import couponLogo from "../assets/cupom.png";
import freeshippingLogo from "../assets/freeshipping.png";

interface ProductInfoProps {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string | any;
  shipping?: string;
  link?: string;
  id?: number;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  imageUrl,
  title,
  price,
  dialogs,
  coupon,
  shipping,
  link,
}) => {
  const [isCopied, sertIsCopied] = useState(false);
  const isMercadolivre = link?.includes("mercadolivre");
  const isShopee = link?.includes("shopee");
  const isMagalu = link?.includes("magazine");
  const isAmazon = link?.includes("amzn");
  const isNatura = link?.includes("natura");

  let logoSrc;
  if (isMercadolivre) {
    logoSrc = mercadolivreLogo;
  } else if (isShopee) {
    logoSrc = shopeeLogo;
  } else if (isMagalu) {
    logoSrc = magaluLogo;
  } else if (isAmazon) {
    logoSrc = amazonLogo;
  } else if (isNatura) {
    logoSrc = NaturaLogo;
  }

  const fetchCoupon = async (e: any) => {
    navigator.clipboard.writeText(coupon);
    sertIsCopied(true);
  };

  const truncateTitle = (coupon: string | undefined): string => {
    let maxLength = 3;
    if (!coupon) return "";
    if (isCopied) {
      maxLength = 5;
      return coupon.length > maxLength
        ? `${coupon.substring(0, maxLength)}...`
        : coupon;
    }
    return coupon.length > maxLength
      ? `${coupon.substring(0, maxLength)}...`
      : coupon;
  };

  return (
    <div className="product-card-select">
      <div className="product-image-wrapper"></div>
      <img src={imageUrl} alt="linkdaimagem" className="product-image-card-select" />
      <div className="product-info-price">
        <div className="product-info-select">
          {logoSrc && (
            <div className="logo-container-select">
              <img src={logoSrc} alt="logosrcs" className="platform-logo" />
            </div>
          )}
          <div className="title-shipping">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="product-price">
          <p className="title-price">Por apenas</p>
          <p className="price">R${price}</p>
        </div>
      </div>
      <div className="product-footer-select">
        {shipping && (
          <span className="shipping">
            <img src={freeshippingLogo} alt="freeshipping" className="freeshipping-logo" />
            <div className="title-shipping">
              <p className="title-one">Aqui tem frete grátis!</p>
              <p className="title-two">Confira disponibilidade para seu CEP</p>
            </div>
          </span>
        )}
        {coupon && (
          <span className="coupon">
            <div className="tilte-coupon">
              <img src={couponLogo} alt="cupom" className="coupon-logo" />
              <span>Use o cupom:</span>
            </div>
            <div className="coupon-button">
              <button>
                {truncateTitle(coupon)}
                <p onClick={(e) => fetchCoupon(e)}>
                  {isCopied ? "copiado!" : "copiar!"}
                </p>
              </button>
            </div>
          </span>
        )}
      </div>
      <div className="product-redirect">
        <a href={link}>
          <button>Pegar este achado!</button>
        </a>
        <p>Preço e disponibilidade sujeito a altereção a qualquer momento!</p>
      </div>
    </div>
  );
};

export default ProductInfo;
