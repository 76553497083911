import React, { useEffect, useState } from "react";
import { formatShopName } from "../utils/formatShopName";
import ProductCard from "./ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Footer from "./Footer";
interface Product {
  imageUrl?: string;
  title?: string;
  price?: string;
  dialogs?: string;
  coupon?: string;
  shipping?: string;
  link?: string;
  id: number;
  createdAt: Date | string; // Campo criadoAt pode ser Date ou string
}

interface ProductLayoutProps {
  shopName: string;
  headerColor: string;
  backgroundcolor: string;
  produtos: Product[];
  linkdogrupo: string;
  logourl: string;
}

const ShopLayout: React.FC<ProductLayoutProps> = ({
  shopName,
  produtos,
}) => {
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    const initialItemsPerPage = 10;

    const convertToDate = (product: Product) => ({
      ...product,
      createdAt:
        typeof product.createdAt === "string"
          ? new Date(product.createdAt)
          : product.createdAt,
    });

    const sortedProducts = produtos
      .map(convertToDate)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    setDisplayedProducts(sortedProducts.slice(0, initialItemsPerPage));
  }, [produtos]);

  const loadMoreProducts = () => {
    if (loading) return;

    setLoading(true);

    setTimeout(() => {
      const itemsPerPage = 10;
      const nextPage = currentPage + 1;
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Converte createdAt para Date, se necessário
      const convertToDate = (product: Product) => ({
        ...product,
        createdAt:
          typeof product.createdAt === "string"
            ? new Date(product.createdAt)
            : product.createdAt,
      });

      const sortedProducts = produtos
        .map(convertToDate)
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      const moreProducts = sortedProducts.slice(startIndex, endIndex);

      setDisplayedProducts((prev) => [...prev, ...moreProducts]);
      setCurrentPage(nextPage);
      setLoading(false);
    }, 300);
  };

  return (
    <div
      style={{
        backgroundColor: '#F0F2F5',
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <header
        style={{
          backgroundColor: '#1A4BAF',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="header"

      >
        <div className="header-content">
          <img src="https://divulgadorinteligente.com/cdn-cgi/imagedelivery/MZSoNqg03WJMyxIyXeG5YA/848b4999-93d5-4b03-445a-c81ed97f9a00/medium" className="shop-header-logo" alt="Logo da Loja" />
          <h5
            style={{
              color: "white",
              fontSize: "17px",
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            {formatShopName(shopName)}
          </h5>
        </div>
        <nav>
          <a href="/" style={{ textDecoration: "none", color: "#fff" }}>
            Início
          </a>
        </nav>
      </header>

      <main className="container-products">
        <h2 style={{ color: "black", padding: "20px" }}>Novidades na loja</h2>
        {displayedProducts && displayedProducts.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              gap: 25,
            }}
          >
            <div className="product-grid">
              {displayedProducts.map((product, index) => (
                <ProductCard
                  key={index}
                  imageUrl={product.imageUrl || ""}
                  title={product?.title || ""}
                  price={product?.price || ""}
                  dialogs={product?.dialogs || ""}
                  coupon={product?.coupon || ""}
                  shipping={product?.shipping || ""}
                  link={product?.link}
                  id={product.id}
                />
              ))}
            </div>
            <div className="contact">
              <p>Já está no meu grupo de promoções?</p>
              <span>
                Receba no Whatsapp as melhores promoções e economize mais.
              </span>
              <a href="https://promosninjas.com.br/whatsapp" style={{ borderRadius: '50px' }}>
                <img
                  src="https://img.icons8.com/?size=100&id=16712&format=png&color=ffffff"
                  alt="link whatsApp"
                />
                Clique aqui e entre
              </a>
            </div>
          </div>
        ) : (
          <p>Sem novos produtos disponíveis no momento.</p>
        )}
      </main>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "1px",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={loadMoreProducts}
              style={{
                padding: "10px 20px",
                fontSize: "10px",
                backgroundColor: "#fff",
                color: "#957097",
                borderRadius: "20px",
              }}
            >
              Olhar mais produtos
            </Button>
          )}
        </div>
      </div>
      <Footer shopName={shopName} />
    </div>
  );
};

export default ShopLayout;
