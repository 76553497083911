// src/services/api.ts
import axios from "axios";

// const apiLocal = "http://localhost:3000";
const apiRemote = "https://apininjaspromo-production.up.railway.app";
const api = axios.create({
  baseURL: apiRemote,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

export default api;
