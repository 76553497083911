import React from "react";
import trustedCloudFlare from "../assets/trusted-google1.png";
import trustedgoogleLogo from "../assets/trusted-google2.png";
import gerapromosLogo from "../assets/gerapromos.png";

interface FooterProps {
    shopName: string;
}

const Footer: React.FC<FooterProps> = ({ shopName }) => {

    const yearShop = new Date().getFullYear()

    return (
        <footer style={{ backgroundColor: 'transparent', padding: '20px', textAlign: 'center' }}>
            <div style={{ marginBottom: '20px', color: 'black', }}>
                <p>
                    Direitos autorais @ {yearShop} - PromosNinja<br />
                    Como participante do Programa de Associados da Amazon, recebo comissão pelas compras qualificadas efetuadas.<br />
                    Amazon e o logotipo da Amazon são marcas comerciais da Amazon.com, Inc. ou de suas afiliadas.
                </p>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <img src={trustedCloudFlare} alt="Trusted Google" style={{ margin: '0 10px', height: '80px' }} />
                <img src={trustedgoogleLogo} alt="Trusted Google" style={{ margin: '0 10px', height: '80px' }} />
            </div>
            <div>
                <p style={{ marginBottom: '10px' }}>Powered by</p>
                <a href="https://gerapromos.com.br">
                    <img src={gerapromosLogo} alt="GeraPromos" style={{ height: '40px' }} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
